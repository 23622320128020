<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark> -->
    <v-app-bar class="header">
      <!-- <v-app-bar-nav-icon
        @click="drawer = true"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon> -->
      <span class="logo"> <router-link to="/">nevComp</router-link></span>
      <!-- <v-toolbar-title>nevComp</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <!-- <span class="nav-link"
        ><router-link to="/ourwork">Our Work</router-link></span
      > -->
      <!-- <span class="nav-link"
        ><router-link to="/carrier">Carrier</router-link></span
      > -->
    </v-app-bar>
    <!-- Add a navigation bar -->
    <!-- <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in items" v-bind:key="index">
            <v-list-item-title @click="tab = index">{{
              item
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <!-- Navigation bar ends -->
    <router-view></router-view>
    <!-- </v-app-bar> -->

    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-title style="background-color: #665e5e !important">
          <strong class="subheading"
            >Get connected with us on social network!</strong
          >

          <v-spacer></v-spacer>

          <v-btn
            v-for="icon in icons"
            :key="icon"
            target="_blank"
            href="https://www.linkedin.com/in/nevcomp-46b35823a/"
            class="mx-4"
            dark
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          © {{ new Date().getFullYear() }} <strong>nevcomp</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
// import Home from "./components/Home.vue";
// import OurWork from "./components/OurWork.vue"
// import ContactUs from "./components/ContactUs.vue";
// import Carrier from "./components/Carrier.vue";
export default {
  name: "App",
  // components: {
  //   "home-page": Home,
  //   "contact-us": ContactUs,
  //   vacancy: Vacancy,
  // },
  data: () => ({
    drawer: false,
    tab: null,
    items: ["Home", "Vacancy", "Contact us"],
    icons: ["mdi-linkedin"],
  }),
};
</script>
<style scoped>
.header {
  box-shadow: none !important;
  background: #00000003 !important;
  z-index: 9;
}
.logo a {
  text-decoration: none;
  font-size: 37px;
  font-weight: bold;
  color: #665e5e;
}
.logo {
  position: absolute;
  left: 3%;
  padding-top: 10px;
}
.nav-link a {
  font-size: 20px;
  text-decoration: none;
  font-weight: bold;
  color: teal;
}
.nav-link {
  margin: 0 1%;
}
.v-footer {
  margin-top: 3%;
}
</style>
